section.link-section {
  &:before {

    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: @brand-black;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: .5;
  }
  @supports (mix-blend-mode: color) {

    &:before {
      mix-blend-mode: color;
      opacity: 1;
    }
  }

}



.services-row {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;

  .column {
    background-position: center center !important;
    background-size: cover !important;
    width: 100%;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
    padding: 0 0 0 0;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    h2 {
      padding: 7px 10px;
      border: 1px solid fade(@brand-text-on-dark, 0%);
      transition: border @transition-time-global;
      font-size: 18px;
      text-shadow: 2px 2px 0px @brand-black;
    }

    .text-object {
      position: relative;
      z-index: 2;
    }

    &:before {
      transition: background @transition-time-global;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: @brand-black;
      background: transparent;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: .5;
    }
    @supports (mix-blend-mode: color) {

      &:before {
        mix-blend-mode: color;
        opacity: 1;
      }
    }

    &:hover {
      &:before {
        background: @brand-primary;
      }
    }
    a {
      z-index: 11;
      display: inline-flex;
      align-items: center;
      width: 100%;
     .text-object {
       width: 100%;
       padding-left: ( @grid-gutter-width * .5 );
       padding-right: ( @grid-gutter-width * .5 );
     }
    }
    @media (max-width: @screen-sm-min) {
      width: 100%;
      a .text-object{
        padding-top: 20%;
        padding-bottom: 20%;
        box-sizing: border-box;
      }
    }
    @media (min-width: @screen-sm-min) {
      width: 50%;
      a .text-object{
        padding-top: 20%;
        padding-bottom: 20%;
      }

      &:last-child {
        width: 100%;
        a .text-object{
          padding-top: 10%;
          padding-bottom: 10%;
        }
      }
    }
    @media (min-width: @screen-md-min) {
      width: 33.333333333%;
      a .text-object{
        padding-top: 35%;
        padding-bottom: 35%;
      }

      &:nth-child(5), &:nth-child(6) {
        width: 50%;
        a .text-object{
          padding-top: 23.333333333331%;
          padding-bottom: 23.333333333331%;
        }
      }
    }
    @media (min-width: @screen-lg-min) {
      width: 20%;
      a .text-object{
        padding-top: 50%;
        padding-bottom: 50%;
      }

      &:nth-child(5), &:nth-child(6) {
        width: 20%;
        a .text-object{
          padding-top: 50%;
          padding-bottom: 50%;
        }
      }
      &:last-child {

        a .text-object{
          padding-top: 50%;
          padding-bottom: 50%;
        }
      }
    }

  }
}

.address-phone {
  address {
    display: inline;
  }
  i {

    margin-bottom: 0px;
    margin-right: .25em;
  }

  a {
    padding-left: 1em;
    i.fa {

      margin-left: -1em;
      color: @brand-secondary !important;
    }
    margin-bottom: (@grid-gutter-width * @paragraph-margin-factor * .5);
    width: 100%;
    display: inline-block;
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
}

section.landing-link-page {
  .row {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    .column {
      background-size: auto 110% !important;
      background-position: center center !important;
      transition: background-size @transition-time-global;
      z-index: 2;
      position: relative;
      display: flex;
      align-content: center;
      flex-wrap: wrap;
      > .text-object {
        width: 100%;
      }
      a.full-link {
        width: 100%;
      }
      &:before {
        transition: background @transition-time-global;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: @brand-gray-dark;
        background: transparent;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: .5;
      }
      @supports (mix-blend-mode: color) {

        &:before {
          mix-blend-mode: color;
          opacity: 1;
        }
      }

      &.no-before {
        background-size: cover !important;
        a {

        }
        &:before {
          background: transparent;
        }
      }
      &:hover, &:focus, &:active {
        background-size: auto 120% !important;
        &.no-before {
          background-size: cover !important;
          &:before {
            background: transparent;
          }
        }
        &:before {
          background: @brand-primary;
        }
      }
    }
    //.column.column-toggle {
    //  a {
    //    text-decoration: none;
    //    color: @brand-text-on-dark;
    //    &:hover,&:focus{
    //      text-decoration: underline;
    //    }
    //  }
    //}
    //@media(max-width: @screen-md-min ){
    //  .column.column-toggle {
    //    ul {
    //      transition: max-height .25s;
    //      max-height: 0px;
    //      overflow: hidden;
    //
    //    }
    //    &.column-toggle-toggled{
    //      ul {
    //        max-height: 600px;
    //        overflow: hidden;
    //      }
    //    }
    //  }
    //}

    @media (max-width: @screen-sm-min) {
      .column {
        padding: 0 0 0 0;
        background: @brand-gray-dark;
        width: 100%;
        &.no-before, a.full-link {
          min-height: 50vw;
          height: auto;
          padding: 20vw 10vw;
        }
      }
    }

    @media (min-width: @screen-sm-min) {
      .column {
        background: @brand-gray-dark;
        width: 50%;
        padding-left: 0px;
        padding-right: 0px;

        &.no-before, a.full-link {
          min-height: 50vw;
          padding: 10vw 10vw;
        }
      }
    }
    @media (min-width: @screen-md-min) {
      .column {
        background: @brand-gray-dark;
        width: 50%;

        &.no-before, a.full-link {
          min-height: 0px;
          padding: 10vw 10vw;
        }
      }
    }

    @media (min-width: @screen-lg-min) {
      .column {
        background: @brand-gray-dark;
        width: 33.3333%;
        height: auto;

        &.no-before, a.full-link {
          padding: 10vw 5vw;
        }
      }
    }
    @media (min-width: @screen-xl-min) {
      .column {
        background: @brand-gray-dark;
        width: 33.3333%;
        &.no-before, a.full-link {
          padding: 10vw 5vw;
        }
      }
    }

  }
}