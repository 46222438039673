footer {
  background: @brand-black;
  color: #fff;
  display: block;
  float: left;
  position: relative;
  width: 100%;
  @media ( min-width: @screen-lg-min) {
    .logo-container {
      //width: auto;
    }
  }

  @media ( max-width: @screen-md-min) {
    .logo-container {
      text-align: center;
      float: left;
      width: 100%;
    }
  }

  img.inline-logo {
    width: 100%;
    max-width: 250px;
    margin-bottom: (@grid-gutter-width * @paragraph-margin-factor);
  }

  h4 {
    font-size: 1.5em;


  }
  a {
    color: @brand-text-on-dark;
    &:hover {
      color: @brand-tertiary;
    }
    font-family: @headings-font-family;
    font-weight: 400;

    margin-bottom: 1.25em;
  }
  ul {
    font-size: 1.125em;
    padding-left: 0px;
    list-style: none;
    margin-bottom: 0px;
    li {

      a {
        color: @brand-text-on-dark;
        &:hover {
          color: @brand-tertiary;
        }
        font-family: @headings-font-family;
        font-weight: 400;
        margin-bottom: .5em;
      }

      li {
        margin-bottom: .5em;
        padding-left: 1em;
        a {
          margin-bottom: 0px;
          font-size: .75em;
          font-family: @font-family-base;
          font-weight: @font-weight-base;
          //color: @brand-text-on-dark;
        }
          &:before {
          margin-left: -1em;
          content: '\25A0';
          //color: @brand-secondary !important;
          display: inline-block;
          width: 1em;
          margin-top: -0.125em;
          float: left;
        }
        font-family: @font-family-base;
        padding-left: 1em;
        li {
          padding-left: 2em;
        }
      }
      a {

        //color: @brand-text-on-dark !important;
        display: inline-block;
        width: 100%;
        padding: 0em;
        &:hover {
          //color: @brand-gray-light !important;
          text-decoration: none;
        }
      }
      ul {
        margin-bottom: 0px;
        font-size: 1em;
        text-transform: none;
        li {
          font-family: @font-family-base;
          font-weight: @font-weight-base;
          margin-bottom: 0em;
          a {

          }
        }
      }
    }
  }

  .row {
    &.footer-widget-area {
      display: flex;
      align-items: stretch;

      > div {
        padding-top: 1em;
        padding-bottom: 1em;
        border-left: 1px solid @brand-gray;
        border-right: 1px solid @brand-gray;
        @media(min-width: @screen-sm-max){
          + div {
            border-left: 0px;
          }
        }


        @media(max-width: @screen-xs-max){
          border-left: 0px;
          width: 100%;
          border-right: 0px;
        }

      }
    }
    @media(max-width: @screen-md-min){
      padding-bottom: 2em;
      h1,h2,h3,h4,ul {
        text-align:left;
      }
      &.footer-widget-area {

        display: flex;
        justify-content: center;
        flex-wrap: wrap;

      }
    }

  }
  .after-footer {
    ul.menu {
      display: flex;
      align-items:center;
      flex-wrap: wrap;
      li {
        width: auto;
        padding: 0 0 0 0;
        a {
          padding: .25em .5em;
          margin: 0 0 0 0;

        }
      }
    }
  }
}

















section.pre-footer {
  text-align:center;

  background: url('../images/pre-footer-image/bg-dark-wood.png');
  background-size: auto;
  a,span {
    display: inline-block;
    text-align:center;
    width: 100%;
  }
  img{
    width: auto;
    max-width: 100%;
  }
  .center-col {
    border: 1px solid @brand-gray;
    border-top: 0px solid transparent;
    border-bottom:0px solid transparent;
    img {
      //margin-bottom: 2em;
    }
    a {
      padding-top: .5em;
      padding-bottom: .5em;
      display: inline-block;
      font-size: 32px;
      text-transform: uppercase;
      font-weight: @headings-font-weight;
      font-family: @headings-font-family;
      color: @brand-gray;

      &:hover {
        color: @brand-gray-light;
      }

    }
    span {
      padding-left: 2em;
      padding-right: 2em;
    }
    span a {
      border-bottom: 1px solid @brand-gray;
    }
    span:last-child {
      a{ border-bottom:0px;}
    }
  }
  @media(max-width: @screen-sm-min){
    .center-col{
    border: 1px solid @brand-gray;
    border-right: 0px solid transparent;
    border-left:0px solid transparent;
      text-align: center;
    }

    a {
      clear: both;
      width: auto;
      padding-left: 2em;
      padding-right: 2em;
    }
    img.max-160{
      max-width: 160px;
      width: auto;
    }
  }
}
