section#award-header-section {
  @media(max-width:@screen-md-min) {
    .column {
      text-align: center;
      .pt-image {
        width: 100%;

      }
      img {
        display: inline-block;
        width: 320px;
      }
      &.show-column {
        img {
          width: auto;
        }
      }
    }
  }

}
#content .award-winners {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  background: @brand-black;
  .award-winner {
    overflow: hidden;
    background: @brand-primary;
    box-sizing: border-box;
    @local-width: 19.8vw;
    font-size: (@local-width /10);
    width : 20%;
    height: @local-width;
    padding-bottom:  @local-width;
    position: relative;
    @media(max-width: @screen-lg-min){
      @local-width: 24.8vw;
      font-size: (@local-width /10);
      width : 25%;
      height: @local-width;
    }
    @media(max-width: @screen-md-min){
      @local-width: 33.2vw;
      font-size: (@local-width /10);
      width : 33.333%;
      height: @local-width;
    }
    @media(max-width: @screen-sm-min){
      @local-width: 49.8vw;
      font-size: (@local-width /10);
      width : 50%;
      height: @local-width;
    }



    span.year {
      position:absolute;
      display: block;
      padding: .25em ;
      text-align:center;
      font-family: @headings-font-family;
      font-weight: 600;
      color: @brand-black;
      background: fade(@brand-tertiary,70%);
      font-size: 1em;
      top: 0;
      right: 0;

    }
    span.winner {
      font-size: .375em;
      color: @brand-black;
      padding: .25em .5em;
      background: fade(@brand-text-on-dark,80%);
      width: 100%;
      font-family: @headings-font-family;
      font-weight: @headings-font-weight;
      position:absolute;
      bottom: 0;
      left: 0;
      line-height: 1.5em;
      text-align: center;
    }
    a {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      text-align: center;
      position:absolute;
      display: inline-flex;
      justify-content: center;
      &:hover {
        img {
          transform: scale(1.25,1.25);
        }
      }
      img {
        transition:transform .25s;
        display: inline-block;
        max-width: none !important;
        height: 100%;

        width: auto !important;
      }
    }
  }
}
