
#content {

  .heading-wrap {
    .show-header-h1 {
      span.show-date.student-show-date {
        font-size:.5em;
        display: inline-flex;
        align-items: flex-start;
        span {
          margin-right: .125em;
          &:last-child{
            margin-right: 0px;
          }
        }
        span.date-name {
          font-weight: 100;
        }
        span.date-number {
          font-weight: 600;
          margin-right: 0em;
        }
        span.ordinal{
          margin-right: .5em;
          font-size: .5em;
          line-height: 1em;
        }
        span.month-year {
          font-weight: 400;
        }
      }
    }
  }

}
