
//---------------------------------------------------------
//   BEGIN DOCUMENT HANDLING
//---------------------------------------------------------

@gray-base: #000001;
@gray-darker:            lighten(@gray-base, 13.5%); // #222 asdf
@gray-dark:              lighten(@gray-base, 20%);   // #333
@gray:                   lighten(@gray-base, 33.5%); // #555
@gray-light:             lighten(@gray-base, 46.7%); // #777
@gray-lighter:           lighten(@gray-base, 93.5%); // #eee

@brand-success: #49c54c;
@brand-info:            #5bc0de;
@brand-warning:         #f98521;
@brand-danger: #bf0007;

@brand-primary: #e8152c; // #fad72c
@brand-secondary: #1b4dac;
@brand-tertiary: #fde32d;
@brand-quartnary:  #003158;
@brand-quintary: #e4bb90;
@brand-sextary: #6dada1;
@brand-senary: #c31f15;
@brand-octonary: #f779ca;
@brand-nonary: transparent;

@brand-black: #000;
@brand-gray-dark :      #303030;
@brand-gray :           #666;
@brand-gray-light : #dedede;
@brand-gray-ultra-light : #f8f8f8;
@brand-text-on-dark:    #fff;

@overlay-image-opacity: 75%;
@transition-time-global: .125s;

@border-column-width: 1px;
@border-column-style : solid;

//defines the half and half isntance colors
@input-color: @brand-gray-dark;

@padding-increment: 1.5em;

// for border left border top classes

@brand-icon-font: 'ICO';

//---------------------------------------------------------
//        Logo File Definitions
//---------------------------------------------------------


@logo-positive-name: 'logo-positive';
@logo-positive-width: 250px;
@logo-positive-height: 100px;

@logo-positive-nomargin-name: 'logo-positive-nomargin';
@logo-positive-nomargin-width: 147px;
@logo-positive-nomargin-height: 26px;


@logo-negative-name: 'logo-negative';
@logo-negative-width: 178px;
@logo-negative-height: 60px;

@logo-negative-nomargin-name: 'logo-negative-nomargin';
@logo-negative-nomargin-width: 147px;
@logo-negative-nomargin-height: 26px;


.logo-postitive-nomargin {
  background: url("../images/@{logo-positive-nomargin-name}.svg");
  background: url('../images/@{logo-positive-nomargin-name}3x.png');
}
.logo-negative-nomargin {
  background: url("../images/@{logo-negative-nomargin-name}.svg");
  background: url('../images/@{logo-negative-nomargin-name}3x.png');
}



//---------------------------------------------------------
//        Desktop Menu variabes
//---------------------------------------------------------

@top-nav-color : @brand-primary;
@top-nav-hover-color: lighten(@brand-primary, 20%);
@top-nav-border-color: @brand-primary;
@top-nav-fszie : .75em;
@top-nav-bg: linear-gradient(to top, darken(@brand-gray,10%), @brand-gray);
@top-nav-font : @font-family-base;
@top-nav-fweight : 800;
@top-nav-align : left;
@top-nav-social-color: @brand-black;
@top-nav-social-hover: @brand-tertiary;



@header-logo: url("../images/@{logo-positive-name}.svg");
@header-logo-fallback: url('../images/@{logo-positive-name}3x.png');
@header-logo-color-fallback: rgba(0,0,0,0);
@logo-width: (@logo-positive-width * 1 );
@logo-height: (60px * 1);
@logo-adjusted-height: (@logo-height * .75);
@logo-adjusted-width: (@logo-width * .75);
@logo-mobile-height: 50px;
@logo-mobile-width: (@logo-width * .5 );

@main-nav-family : @headings-font-family;
@main-nav-fsize: 1.25em;
@main-nav-fsize-small: 1em;
@main-nav-fstyle: normal;
@main-nav-ftransform: uppercase;
@main-nav-fweight: 600;

@main-nav-height: @logo-height;


@main-nav-adjusted-height: @logo-adjusted-height;
@main-nav-background : @brand-text-on-dark;
@main-nav-color: @brand-primary;
@main-nav-color-hover: @brand-secondary;
@main-nav-top-level-pad: .5em;
@main-nav-top-level-padding: @main-nav-top-level-pad @main-nav-top-level-pad @main-nav-top-level-pad @main-nav-top-level-pad;
@perpval : 1000px; // determines perspecitve of transform

@sub-menu-family: @headings-font-family;
@sub-menu-top: @main-nav-height;
@sub-menu-left: (@sub-menu-width * -.5);
@sub-menu-adjusted-top: @main-nav-adjusted-height;
@sub-menu-padding : 0em 0em;
@sub-menu-fsize: 1em;
@sub-menu-fweight: 400;
@sub-menu-width: 250px;
@sub-menu-width-adjusted: 225px;
@sub-menu-left-adjusted: (@sub-menu-width-adjusted * -.45);

@sub-menu-transition-max-height : 300px;
@sub-menu-bg: @brand-text-on-dark;
@sub-menu-color: @brand-primary;
@sub-menu-color-hover: @brand-secondary;
@sub-menu-link-padding-vert: .5em;
@sub-menu-link-padding-horz: .5em;
@sub-sub-menu-link-padding-vert: @sub-menu-link-padding-vert / 2;
@sub-sub-menu-link-padding-horz: 2em;
@sub-menu-seperator-color: @brand-gray-dark;

//---------------------------------------------------------
//        Mobile Menu Variables
//---------------------------------------------------------
@main-nav-height-mobile: 100px;

@mobile-item-padding-horz: (@grid-gutter-width / 2);
@mobile-item-padding-vert: 1em;

@scrollLockColor: @brand-black;



//---------------------------------------------------------
//        Footer variabes
//---------------------------------------------------------

@footer-logo: url("../images/@{logo-negative-nomargin-name}.svg");
@footer-logo-fallback: url('../images/@{logo-negative-nomargin-name}3x.png');
@footer-logo-color-fallback: rgba(0,0,0,0);
@footer-logo-width: @logo-negative-nomargin-width;
@footer-logo-height: @logo-negative-nomargin-height;


//---------------------------------------------------------
//        Form variabes
//---------------------------------------------------------

@form-color : @brand-black;
@form-border : @brand-black;
@form-background : @brand-primary;


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
@body-bg:               #fff;
//** Global text color on `<body>`.
@text-color:            @brand-black;

//** Global textual link color.
@link-color:            @brand-primary;
//** Link hover color set via `darken()` function.
@link-hover-color:      darken(@link-color, 15%);
//** Link hover decoration.
@link-hover-decoration: none;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.


@font-family-sans-serif:  'Barlow', Helvetica, Arial, sans-serif;
@font-family-serif:       'Barlow', Helvetica, Arial, sans-serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
@font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-sans-serif;


@font-size-base:          1em;
@font-size-large:         (@font-size-base * 1.5); // ~18px
@font-size-small:         (@font-size-base * 0.875); // ~12px


//** Unit-less `line-height` for use in components like buttons.
@line-height-base-value : 1.5;
@line-height-base:        1.5em; // 20/14
@btn-line-height-base : 1em;
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
@line-height-computed:    floor((@font-size-base * @line-height-base)); // ~20px
//@line-height-computed:    1.3em; // ~20px

@font-weight-base: 400;




//** By default, this inherits from the `<body>`.
///THE ONE WE ACTUALLY WANT


@headings-font-family:    'Barlow Condensed', Helvetica, Arial, sans-serif;
@headings-font-style: normal;
@headings-font-weight:    600;
@headings-line-height:    .775em;
@headings-color:          inherit;
@headings-light-weight: 100;

@strong-weight: 600 ;
@strong-headings-weight: 400;

// SETS DEFAULT SIZE THAT ALL EMS ARE BASED UPON.
@base-em-size: 16px ;




// MARGIN FACTORS DETERMINE SPACINGS OF PARAGRAPHS AND HEADINGS BY DOING MATHS ON GRID GUTTERS
// MARGIN FACTORS DETERMINE SPACINGS OF PARAGRAPHS AND HEADINGS BY DOING MATHS ON GRID GUTTERS
// MARGIN FACTORS DETERMINE SPACINGS OF PARAGRAPHS AND HEADINGS BY DOING MATHS ON GRID GUTTERS
// MARGIN FACTORS DETERMINE SPACINGS OF PARAGRAPHS AND HEADINGS BY DOING MATHS ON GRID GUTTERS
// MARGIN FACTORS DETERMINE SPACINGS OF PARAGRAPHS AND HEADINGS BY DOING MATHS ON GRID GUTTERS
// MARGIN FACTORS DETERMINE SPACINGS OF PARAGRAPHS AND HEADINGS BY DOING MATHS ON GRID GUTTERS
// MARGIN FACTORS DETERMINE SPACINGS OF PARAGRAPHS AND HEADINGS BY DOING MATHS ON GRID GUTTERS
// MARGIN FACTORS DETERMINE SPACINGS OF PARAGRAPHS AND HEADINGS BY DOING MATHS ON GRID GUTTERS
@grid-gutter-width:          floor(((@base-em-size * @line-height-base-value) * 2));
@paragraph-margin-factor:           .5;
@heading-margin-factor:             .25;
@default-column-padding-factor:     @paragraph-margin-factor;
@default-section-padding-factor:    @paragraph-margin-factor;
@column-horz-phone-padding : 1;
// MARGIN FACTORS DETERMINE SPACINGS OF PARAGRAPHS AND HEADINGS BY DOING MATHS ON GRID GUTTERS
// MARGIN FACTORS DETERMINE SPACINGS OF PARAGRAPHS AND HEADINGS BY DOING MATHS ON GRID GUTTERS
// MARGIN FACTORS DETERMINE SPACINGS OF PARAGRAPHS AND HEADINGS BY DOING MATHS ON GRID GUTTERS
// MARGIN FACTORS DETERMINE SPACINGS OF PARAGRAPHS AND HEADINGS BY DOING MATHS ON GRID GUTTERS
// MARGIN FACTORS DETERMINE SPACINGS OF PARAGRAPHS AND HEADINGS BY DOING MATHS ON GRID GUTTERS
// MARGIN FACTORS DETERMINE SPACINGS OF PARAGRAPHS AND HEADINGS BY DOING MATHS ON GRID GUTTERS
// MARGIN FACTORS DETERMINE SPACINGS OF PARAGRAPHS AND HEADINGS BY DOING MATHS ON GRID GUTTERS

// text - size modidfers these are wrapping classes that balloon all text sizes relatively within them.

@text-large: 1.5;
@text-xlarge: 2;
@text-xxlarge: 2.25;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.
@btn-font-family: @headings-font-family;
@btn-font-weight:                600;
@btn-border-width: 2px;

@btn-default-color:              @brand-text-on-dark;
@btn-default-bg:                 @brand-primary;
@btn-default-border:             @brand-primary;

@btn-primary-color:              @brand-text-on-dark;
@btn-primary-bg:                 @brand-primary;
@btn-primary-border:             darken(@btn-primary-bg, 5%);

@btn-success-color:              #fff;
@btn-success-bg:                 @brand-success;
@btn-success-border:             darken(@btn-success-bg, 5%);

@btn-info-color:                 #fff;
@btn-info-bg:                    @brand-info;
@btn-info-border:                darken(@btn-info-bg, 5%);

@btn-warning-color:              #fff;
@btn-warning-bg:                 @brand-warning;
@btn-warning-border:             darken(@btn-warning-bg, 5%);

@btn-danger-color:               #fff;
@btn-danger-bg:                  @brand-danger;
@btn-danger-border:              darken(@btn-danger-bg, 5%);

@btn-link-disabled-color:        @gray-light;





@btn-brand-color:               @brand-text-on-dark;
@btn-brand-bg:                  @brand-primary;
@btn-brand-border:              @brand-primary;



@btn-secondary-color:               @brand-text-on-dark;
@btn-secondary-bg:                  @brand-secondary;
@btn-secondary-border:              @brand-secondary;

@btn-tertiary-color:               @brand-black;
@btn-tertiary-bg:                  @brand-tertiary;
@btn-tertiary-border:              @brand-tertiary;


@btn-padding-base-vertical:     .5em;
@btn-padding-base-horizontal:   1em;
@button-correction: false;
@btn-bottom-correction: .5em;
@btn-top-correction: .65em;

@letter-spacing-correction: -0.025em;
@tex-indent-correction: 0em;



@sidebar-border-color : @brand-primary;


body *::selection {
  background:@brand-primary;
  color: #fff;
}

@blockquote-small-color:      @brand-gray-dark;
//** Blockquote font size
@blockquote-font-size:        (@font-size-base * 1.125);
//** Blockquote border color
@blockquote-border-color:     @brand-secondary;




.pad-fixer (@btn-correction) when (@button-correction = true ){
  padding-bottom: @btn-bottom-correction;
  padding-top: @btn-top-correction;
}
