#grid-classic {
  background: url('../images/Autorama-Images/bg-diamond.png');
  background-size: auto auto;
  background-repeat: repeat;

  #grid {
    @local-font-size: (16px * .4);
    font-size: 1.4vw;
    line-height: (@line-height-base-value * @local-font-size);

    div.column {
      padding: (@grid-gutter-width/4/4);

      .block {
        margin-bottom: (@grid-gutter-width/2/4);

        h1, h2, h3, h4, h5, a, p {
          margin-top: 1em;
          margin-bottom: 0;
        }
      }
    }

    @media (min-width: @screen-sm-min) {
      @local-font-size: (16px * 0.64041);
      font-size: @local-font-size;
      line-height: (@line-height-base-value * @local-font-size);
      div.column {
        padding: (@grid-gutter-width/4/2);

        .block {
          margin-bottom: (@grid-gutter-width/2/2);

        }
      }

    }
    @media (min-width: @screen-md-min) {
      @local-font-size: (16px * 0.828767);
      font-size: @local-font-size;
      line-height: (@line-height-base-value * @local-font-size);
      div.column {
        padding: (@grid-gutter-width/4*.75);

        .block {
          margin-bottom: (@grid-gutter-width/2*.75);
        }
      }
    }
    @media (min-width: @screen-lg-min) {
      @local-font-size: 16px;
      font-size: @local-font-size;
      line-height: (@line-height-base-value * @local-font-size);
      div.column {
        padding: (@grid-gutter-width/4);

        .block {
          margin-bottom: (@grid-gutter-width/2);
        }
      }
    }

    div.column {
      box-sizing: border-box;
      width: 33%;
      float: left;
      display: block;


    }
  }
}


@fractional-pad: .375em;
#grid-classic {
  h2.grid-header {

    font-weight: 600;

    strong {
      width: 100%;
      font-weight: 400;
      font-size: 1.25em;
      line-height: @headings-line-height;
      display: inline-block;
    }
  }

  #grid {
    div.column {
      .block {
        text-align: center;
        padding: 1.5em;
        box-shadow: 0 .25em .25em fade(@brand-black, 50);

        &.full-bleed {
          padding: 0 0 0 0;
        }

        img {
          width: 100%;

          + div.pad {
            padding-top: 2em;
          }
        }

        > * > *, > * {
          width: 100%;
        }

        div.pad {
          padding: 1.5em;
          padding-top: 0em;

          &:first-of-type {
            padding-top: 1.5em;
          }

          &:last-child {
            padding-bottom: 1.5em;
          }

          > *:first-child {
            margin-top: 0px;
          }

          + div.pad {


          }

          h4 {

          }
        }

        h4 {
          margin: @fractional-pad 0 0 0;
          padding: 0 0  (@fractional-pad * .75) 0;
          line-height: 1em;
          font-size: 2.25em;
          border-style: solid;
          border-left-width: 0;
          border-right-width: 0;
          border-top-width: 0;
          border-bottom-width: 1px;

          &:only-child {
            border-bottom-width: 0;
          }

          &:first-child {
            padding-top: (@fractional-pad * .75);
          }

          + .time {
            border-top-width: 0px;
          }

          + .presents, .subtitle {
            border-top-width: 0px;
          }
        }

        .presents, .subtitle {
          padding-top: @fractional-pad;
          padding-bottom: @fractional-pad;
          font-family: @headings-font-family;
          text-transform: uppercase;
          width: 100%;
          display: inline-block;
          //margin-bottom: @fractional-pad;
          border-style: solid;
          border-left-width: 0;
          border-right-width: 0;
          border-top-width: 1px;
          border-bottom-width: 1px;

          + .time {
            margin-top: 0px;
          }
        }

        .time {
          width: 100%;
          font-size: 1.5em;

          border-style: solid;
          border-left-width: 0;
          border-right-width: 0;
          border-top-width: 1px;
          border-bottom-width: 1px;


          padding: @fractional-pad 0;
          margin: @fractional-pad 0;

          + .time {
            margin: (-1 * @fractional-pad) 0 @fractional-pad 0;
            border-top-width: 0;
          }

          &:last-child {
            margin-bottom: 0px;
          }

          box-sizing: border-box;

          display: inline-flex;
          align-items: center;
          flex-wrap: wrap;
          font-family: @headings-font-family;
          font-weight: 400;
          text-transform: uppercase;


          p {
            display: inline-flex;
            align-items: center;
            width: 100%;
            margin: 0 0 0 0;
            justify-content: center;

            + p {
              margin-top: ( @fractional-pad );
              padding-top: @fractional-pad;
              border-top-width: 1px;
            }
          }

          p span {
            font-size: 1.25em;
            font-weight: 600;
            display: inline-block;

            &:after {
              content: '|';
              margin: 0 @fractional-pad;
              font-weight: 400;
            }
          }
        }

        h4 + .time, .presents + .time {
          margin-top: 0;
        }
      }
    }
  }
}

.text-shadow-fix(@color) {
  h4 {
    text-shadow: .1em .1em 0px @color, .15em .15em 0px #000000;
  }
  .time span {
    text-shadow: .1em .1em 0px @color, .15em .15em 0px #000000;
  }
}

#grid-classic #grid div.column .block {
  &.bg-quartnary {
    color: red;
    .text-shadow-fix(#031e39);
  }

  &.bg-sextary {
    @color: #5e2f4f;
    .text-shadow-fix(@color);
  }
}










