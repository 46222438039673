.heading-wrap.show-header-background {
  background: url('../images/Autorama-Images/bg-logos-area.png');
  background-size: cover;

  div.show-heading-justifier {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: @screen-md-min) {
      justify-content: center;

    }
  }

  div.show-type {
    width: 100%;
    @media (min-width: @screen-sm-min) {
      width: auto;
    }
    text-align: center;

    padding-top: (@grid-gutter-width/2);
    padding-bottom: (@grid-gutter-width/2);
  }

  h1.show-header-h1 {
    @media (min-width: @screen-sm-min) {
      padding-left: (@grid-gutter-width /2);
    }
    @media(max-width: @screen-xs-max){
      text-align:center;
    }
    width: auto;
    display: inline-block;
    margin-bottom: 0px;

    span {
      display: inline-block;
      clear: both;
      float: left;
    }

    span.show-name-type {
      font-size: .25em;
      line-height: @headings-line-height;
      font-weight: 400;
      width: 100%;
      height: 0px;
      overflow: hidden;

      letter-spacing: 0;
      margin-bottom: (@grid-gutter-width / 6);
    }

    span.show-region {
      margin-bottom: (@grid-gutter-width / 6);
    }

    span.show-date {
      font-size: .75em;
      line-height: @headings-line-height;
      width: 100%;
      letter-spacing: 0;
      font-weight: 400;
      float: left;
    }
  }
}

h2.past-disclaimer {
  //font-size: 4em;
  line-height: @headings-line-height;
  margin-bottom: 0px;
}

span.past-disclaimer {
  font-family: @headings-font-family;
  text-transform: uppercase;
  display: inline-block;
  width: 100%;
}

#show-main {
  background: url('../images/Autorama-Images/gray-wood.png');
  background-repeat: repeat !important;
  background-size: auto auto !important;

  #show-container {
    background: url('../images/Autorama-Images/bg-speedometer.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
  }

  #show-display-header {
    h2 {
      //color: @brand-secondary;
      font-size: 3em;
    }
    strong {
      margin-top: 1em;
      font-size: 2.5em;
      font-family: @headings-font-family;
      font-weight: @headings-font-weight;
      width: 100%;
      display: inline-block;
      text-transform: uppercase;
    }

    img {
      display: inline-block;
      vertical-align: middle;

      max-width :300px;

    }

  }

  .show-menu {
    a.btn.btn-primary {
      margin-bottom: (@grid-gutter-width / 4);
      width: 100%;
    }
  }

  .admission-information {
    .admission-block {
      background: fade(@brand-text-on-dark, 50);
      padding: (@grid-gutter-width/2);
      //border: 1px solid @brand-gray-dark;
      box-shadow: 0px 3px 2px fade(@brand-black, 50);

      .admission-image {
        margin-top: -0.7em;
        margin-left: .25em;
        position: absolute;
        height: 2em;
      }
    }

    h3 {
      font-size: 4em;
      margin-bottom: .5em;
    }

    table.pricing-table {
      width: 100%;
      margin-bottom: (@grid-gutter-width / 4);

      td {
        padding-top: (@grid-gutter-width / 4);
        padding-bottom: (@grid-gutter-width / 4);
        vertical-align: bottom;
        border-bottom: 1px solid @brand-gray-dark;

        ~ td {
          padding-left: (@grid-gutter-width / 4);
        }

        strong {
          font-size: 1.5em;
          font-family: @headings-font-family;
          font-weight: @headings-font-weight;
        }

        &.price {
          font-size: 1.5em;
          text-align: right;
          font-family: @headings-font-family;
          font-weight: @headings-font-weight;
        }

        .gate {
          font-size: 3em;
          letter-spacing: @letter-spacing-correction;
          text-transform: uppercase;
        }
      }
    }

    .admission-disclaimer {
      padding-top: (@grid-gutter-width / 4);
      padding-bottom: (@grid-gutter-width / 4);
      font-style: italic;
    }

    .discount-ticket-sponsor {
      padding-top: (@grid-gutter-width /4);
      text-align: right;

      a {
        img {
          height: 40px;
          margin-left: (@grid-gutter-width / 4);
          margin-right: (@grid-gutter-width / 4);
          width: auto;
        }
      }
    }
  }

  .show-date-times {

    .show-date-time {
      margin-bottom: (@grid-gutter-width/2);

      &:last-child {
        margin-bottom: 0;
      }

      font-size: 3em;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      font-weight: @headings-font-weight;
      line-height: @headings-line-height;
      font-family: @headings-font-family;
      text-transform: uppercase;
      text-shadow: 1px 2px 0px @brand-text-on-dark;
      padding: (@grid-gutter-width / 4);
      background: fade(@brand-text-on-dark, 50);
      box-shadow: 0px 3px 2px fade(@brand-black, 50);

      + .show-date-time {

      }

      .date {
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 100%;
        padding-bottom: (@grid-gutter-width/4);
        margin-bottom: (@grid-gutter-width/4);
        border-bottom: 1px solid @brand-gray-dark;

        span.day {
          display: inline-flex;
          flex-wrap: nowrap;
          align-items: flex-start;

          span.month {
            font-size: .5em;
            line-height: 1em;
          }
        }

        span.ordinal {
          color: @brand-secondary;
          padding-left: (@grid-gutter-width/4);

          span.ordinal-ordinal {
            font-size: .5em;
            line-height: 1em;
            vertical-align: top;
          }
        }
      }

      .times {
        display: inline-block;
        width: 100%;
        font-weight: 400;
        font-size: 0.675em;
      }
    }
  }

  .location-information {
    //text-align:center;
    .location-information-container {
      text-align: left;
      display: inline-block;
      width: auto;
    }

    h3 {
      font-size: 2em;
      margin-bottom: (@grid-gutter-width/4);
    }

    a.location-logo {
      display: inline-block;
      width: 100%;
      img {
        background: @brand-text-on-dark;
        padding: 1em;
      }

    }

    address {
      background: @brand-black;
      background: fade(@brand-text-on-dark, 50);
      box-shadow: 0px 3px 2px fade(@brand-black, 50);
      padding: 10px;
      width: 100%;
      margin-bottom: (@grid-gutter-width/4);

      span {
        width: 100%;
        display: inline-block;
        margin-top: (@grid-gutter-width/4);
        font-weight: 600;
      }
    }
  }

  .sponsors-and-presenters {
    .sponsor-wrap {
      justify-content: space-around;
    }


    .sponsor-column {
      h3 {
        font-size: 3em;
      }

      .sponsor-item {
        a {
          img {
            width: 220px;
            max-width: 100%;
            margin-top: (@grid-gutter-width / 4);
          }
        }
      }
    }
  }
}


section.featurette-header-section {
  padding-top: (@grid-gutter-width );
  padding-bottom: (@grid-gutter-width * 1.25 );
  background: @brand-black;
  background: url("../images/burnt-rubber-bg.png");
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  @supports (-ms-ime-align: auto) {
    background-attachment: scroll;
  }
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {

  }

  .container h2 {
    color: #fff;
    text-shadow: .5em .5em .1em rgba(0, 0, 0, .5);

    strong {
      color: @brand-tertiary;
      width: 100%;
      display: inline-block;
      font-size: .675em;
      line-height: .675em;
      margin-bottom: .125em;
    }
  }
}
