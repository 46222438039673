#autorama-header {
  .container {
    .row-one,.row-two,.row-three {
      display: inline-flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: center;
      img {
        transform: rotateX(0.01deg);
      }
    }
    .row-one {

      .one{
        width: 33.3333333%;
      }
      .two{
        width: 33.3333333%;
      }
      .three{
        width: 33.3333333%;
      }
      .four{}
      .five{}
      .six{}
    }
    .row-two {

      .one{ width: 100%;
        border-top: 1px solid lighten(@brand-gray-dark,20%);
        border-bottom: 1px solid lighten(@brand-gray-dark,40%);
      }

    }
    .row-three {
      .one{ width: 21.5%;}
      .two{ width: 15.5%;}
      .three{ width: 12.5%;
      border-right:1px solid lighten(@brand-gray-dark,20%);}
      .four{ width: 16%;}
      .five{ width: 16%;}
      .six{ width: 18.5%;}
    }
  }
}
