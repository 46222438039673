.list-sub-item {
  a {
    margin-bottom: 1em;
    font-weight: 600;
    text-decoration: underline;
  }
}

#list-list {
  .list-items {
    border-top: 1px solid @brand-gray-light;
  }
  .list-items:nth-child(even){
    background: @brand-gray-ultra-light;
  }
}


#live-area .award-list {
  display:flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: center;
  background: #000;
  .award-locale{
    position: relative;
    overflow: hidden;
    color: #fff;
    font-size: 100vw;
    width: 100%;
    background-size: cover !important;
    background-position:center center !important;
    z-index: 1;
    &:after {
      content: '';
      position:absolute;
      background: rgba(0,0,0,.35);
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1;
    }
    @media(max-width: @screen-sm-min){
      //width: 50%;
      //height: 50vw;
      //font-size: 50vw;
    }
    @media(min-width: @screen-sm-min){
      width: 50%;
      min-height: 50vw;
      font-size: 50vw;
    }
    @media(min-width: @screen-md-min){
      width: 33.33333333%;
      min-height: 33.33333333vw;
      font-size: 33.33333333vw;
    }

    @media(min-width: @screen-lg-min){
      width: 25%;
      min-height: 25vw;
      font-size: 25vw;

    }

    @media(min-width: 1930px ){
      width: 20%;
      min-height: 20vw;
      font-size: 20vw;
    }

  }
  .award-content {
    text-shadow: .1em .1em .1em rgba(0,0,0,.85);
    z-index: 99;
    display: block;
    padding: 1.5em;
    font-size: .05em;
    h3 {
      padding-bottom: .25em;
      //border-bottom: 1px solid @brand-gray-light;
      font-size: 1.75em;
      margin-bottom: .125em;
      width:auto;
      display :inline-block;
    }
    h4{
      font-size: 1.25em;
      width: 100%;
      display: inline-block;
      //font-weight: 400;
      line-height: .9em;
    }
    span.award-details {
      font-family: @headings-font-family;
      font-weight: 400;
      font-style: italic;
      display: inline-block;
      padding: .25em;
      background: @brand-tertiary;
      color: @brand-black;
      text-shadow: none;
    }
    div.award-item{
      margin-top: .5em;
      margin-bottom: .5em;
      padding-top: .25em;
      padding-bottom: .25em;
      display: inline-block;
      width: 100%;
      box-sizing: border-box;
      padding-left: 1em;
      border-left: 1px solid @brand-gray-light;
    }
  }
}
#live-area .row.award-image-row .column {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  .pt-image {
    margin:  0 0 0 0;
    width: 20%;
    text-align:center;
    padding: 1em;
    img {
      display: inline-block;
      max-width: 220px;
      width: 100%;
    }
  }
}
