.block {
  background: #fff;
}
.btn-list {
  a.btn {
    margin-right: 1em;
    margin-bottom: 1em;
  }
}
#content {
  section {
    .row.sponsor-strip  {
      .column {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-evenly;
        @media(min-width: @screen-md-min){
          display: flex;
          align-items: center;
          flex-wrap: nowrap;

          img {
            width: 100px;
          }
        }
        .pt-image {
          margin: 0 0 0 0;
          padding:(@grid-gutter-width / 4) (@grid-gutter-width / 2);
        }
        img {
          width: 140px;
          max-width: 100%;
        }
      }
    }
  }

}

#content.wraps-page-content {
  padding-top: @grid-gutter-width;
  padding-bottom: @grid-gutter-width;
  table {
    thead td {
      font-weight: 800;

    }
    td {
      border: 1px solid;
    padding: 1em;
      h1,h2,h3,h5,h5,strong {
        color :inherit;
      }
    }
    margin-bottom: 1em;
    margin-top: 1em;
  }
}


section.to-top {
  border-top: 1px solid #690013;
  border-bottom: 1px solid #ffed61;
  padding-top: 0;
  padding-bottom: 0;
  a {
    padding: 1em 2em;
    display: inline-block;
    box-sizing: border-box;
    text-align:center;
    width: 320px;
    font-weight: @headings-font-weight;
    line-height : 1em;
    font-size: 2em;
    font-family: @headings-font-family;
    text-transform: uppercase;
    color: #690013;
    text-shadow: 0px -2px 0px #ffed61,;
    @media(max-width:@screen-sm-min) {
      font-size :1em;
    }
  }
}
section.social-media {
  background: url("../images/burnt-rubber-bg.png");
  background-position: center center;
  background-size:cover;
  padding-top: .5em;
  padding-bottom: .5em;
  background-attachment: fixed;
  box-shadow: inset 0em 0.5em .5em 0em rgba(0, 0, 0, .5);
  border-bottom: 2px solid @brand-tertiary;
  border-top:2px solid @brand-tertiary;
  .container-fluid {
    text-align:center;
  }
  h2,a {
    margin-bottom: 0px;
    font-size: 2em;
    @media(max-width:@screen-sm-min) {
      font-size :1em;
    }
    padding: 1em;
    width: auto;
    display: inline-block;;
    line-height: 1em;
    text-shadow: .5em .5em .1em rgba(0, 0, 0, .5);
  }
  h2 {
    color: #fff;
    span {
      color: @brand-tertiary;
      font-size: 1.25em;
    }
  }
  a {
    color: @brand-tertiary;
    width:auto;
    border-left: 1px solid @brand-gray;
    display: inline-block;
  }
}
