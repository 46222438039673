
#content {
  .home--show-list {

    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;

    .heading-container {
      width: 100%;
      text-align: left;
      box-sizing: border-box;
      padding: (@grid-gutter-width / 2);
      margin-bottom: 0px;

      &.main {
        display: inline-block;
      }
    }
  }
}

#content {
  .home--show-list {
    // BEING THE CONENT

    .home--show-wrapper {
      z-index: 1;
      position: relative;

      .home--show-background {
        background-position: center center !important;
        background-size: cover !important;
        position: relative;
        box-sizing: content-box;
        z-index: -1;
        &:before {
          content: '';
          top: 0;
          left: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 1;
          @local-color: @brand-tertiary;
          mix-blend-mode: multiply;
          background: linear-gradient(to right bottom, fade(@local-color, 80%), fade(@local-color, 0%));
        }
      }


      &.shows-expired {
        .home--show-background {
          filter: grayscale(100%) !important;
        }
        .home--show-car {
          filter: grayscale(100%) !important;
        }

        .home-content-container:hover ~ .home--show-background {

        }
      }

      &:last-child {

      }
    }
  }
}


#content .home--show-list {
  .show-list-container{
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: center;
    .home--show-wrapper {
      overflow:hidden;
      z-index: 1;
      position: relative;
      width :100%;
      font-size: 100vw;
      display: inline-block;
      @media(min-width: @screen-sm-min ){
        width: 50%;
        font-size: 50vw;
      }
      @media(min-width: @screen-md-min ){
        font-size: 33.3333333vw;
        width: 33.333333333%;
      }
      @media(min-width: @screen-lg-min ){
        font-size: 20vw;
        width: 20%;
      }
      @media(min-width: 1930px ){
        @size : (100 / 7);
        font-size: ~"@{size}vw";
        width:  ~"@{size}%";
      }


      .home-content-container {
        font-size: .0675em;
        position:relative;
        width:100%;
        padding: 15%;
        a {
          color: #fff;
        }
      }
      .bottom-space {
        width:100%;
        padding-bottom: 30%;
      }
    }

    .home--show-background {
      background-position: center center !important;
      background-size: cover !important;
      position: absolute;
      left: 0;
      top: 0;
      overflow:hidden;
      width: 100%;
      height: 100%;
      box-sizing: content-box;
      z-index: -1;
      transition: transform .25s;
      border-radius: 0;
      transform-origin: 50% 50%;
      &:before {
        content: '';
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        @local-color: @brand-black;
        mix-blend-mode: multiply;
        background: linear-gradient(to right bottom, fade(@local-color, 80%), fade(@local-color, 0%));
      }
    }
    .home--show-wrapper {
      &:hover {
        .home--show-background {
          transform:scale(1.1, 1.1);

        }
      }
    }
  }
}


.home--show-wrapper {
  .home-content-container {
    .home--details-wrapper {
      height: 10em;
      display: inline-block;
      width: 100%;
      text-shadow: 1px 1px 0px rgba(0, 0, 0, .25);
    }

    .home--show-title h3 {
      font-size: 1.5em !important;
      line-height: 1em !important;
      margin-bottom: 0em !important;
    }

    .home--show-date {
      font-size: 1.875em;
      letter-spacing: -0.0375em;
      line-height: 1em;
      margin-bottom: 0.025em;
      text-transform: uppercase;
      font-family: @headings-font-family;
    }

    .home--show-location {
      line-height: 1em;
      font-size: .75em;
    }

  }

  .home--show-car {
    position: absolute;
    bottom: 6%;
    width: 75% !important;
    left: 12.5%;
  }

  &.up-next {
    .home--details-wrapper {
      &:before {
        content: 'UP NEXT';
        position: absolute;
        right: 0;
        top: 0;
        padding: .5em;
        background: @brand-primary;
        font-size: 0.55em;
        font-weight: 800;
      }
    }

  }

  &.complete {
    .home--details-wrapper {
      &:before {
        content: 'COMPLETE';
        position: absolute;
        right: 0;
        top: 0;
        padding: .5em;
        background: @brand-primary;
        font-size: 0.55em;
        font-weight: 800;
      }
    }
  }
}
